@media screen and(max-width: 48em) {
  #main-content {
    h2 {
      font-size: 1.5rem;
    }

    h3 {
      font-size: 1.5rem;
    }
  }
  .home {
    .hero-image {
      height: auto !important;
      .row {
        position: relative;
        .hero-text-title {
          z-index: 2;
          position: relative;
          padding-top: 70px;

          p {
            font-size: 30px;
          }
        }
        .hero-img-title {
          margin-top: -10rem;
          img {
            width: 100%;
          }
        }
      }
    }

    .planos {
      .row {
        padding: 80px 0px;
      }
      .card {
        height: 100px;
        margin-left: 0px;
      }
    }
    .plans {
      height: auto !important;
      .title-plans {
        margin-bottom: 100px;
        h1::before {
          background: none;
        }
      }
    }

    .history {
      margin-top: 0px;
      margin-bottom: 0px;
      .text-content {
        padding: 15px;
        height: auto;
        display: block;
      }
    }
    #parallax {
      height: 215px;
    }
    .plano-resi-pop {
      margin-top: -25em;
    }
  }

  .top-footer {
    margin-bottom: -175px;

    .footer-info-one {
      margin-top: -50px;
    }
  }

  .g-map iframe {
    height: 100%;
    width: 100%;
  }
}

@media screen and(max-width: 360px) {
  .top-footer {
    .footer-info-one {
      margin-top: -65px;
    }
  }
}

@media screen and(max-width: 320px) {
  .top-footer {
    .footer-info-one,
    .footer-info-two {
      line-height: 1;
      h3 {
        font-size: 1.5rem;
      }
    }
    .footer-info-two .social-links {
      margin-top: 10px;
      .list-inline-item:not(:last-child) {
        margin-right: -6px;
      }
    }
  }
}
