.blog-content {
    padding-top: 8rem;
    padding-bottom: 100px;
    .btn,
    .nav,
    input {
        border-radius: 0;
    }

    .post-item {
        a {
            text-decoration: none;
            color: #000;
        }
    }

    .page-item .page-link {
        color: #000;
    }
    .page-item.active .page-link {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
        transition: background-color 0.3s;
    }

    .page-item .page-link:hover {
        background-color: var(--color-secondary);
        color: #fff;
        border-color: var(--color-secondary);
        transition: background-color 0.3s;
    }

    .page-item:first-child .page-link,
    .page-item:last-child .page-link {
        border-radius: 0;
    }

    .blog-menu-side {
        .card {
            text-align: start !important;
        }
        .btn-secondary {
            background-color: $primary;
            transition: background-color 0.4s;
            &:hover {
                background-color: $secondary;
                border-color: $secondary;
            }
        }
    }

    .btn-secondary {
        background-color: #0e2244;
    }

    nav {
        margin-top: 50px;
    }

    .btn-light {
        color: $primary;
    }

    .btn-dark {
        background-color: $primary;
        transition: background-color 0.4s;
        &:hover {
            background-color: $secondary;
            border-color: $secondary;
        }
    }
}

.post-content {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .media-body {
        a {
            text-decoration: none;
            color: #000;
        }
    }


}

.item-blog {
    color: #6c757d!important;
    text-decoration: none;
}
.item-blog:hover {
    text-decoration: none;
}

.admin {
    a {
        text-decoration: none;
    }
    .btn-secondary {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
        &:hover {
            color: #fff;
            background-color: #5c636a;
            border-color: #565e64;
        }
    }
}

.share-social-media {
    a {
        color: #6c757d!important;
        text-decoration: none;
        border-bottom: none;

        i:hover {
            color: #d7510c;
        }
    }
} 