.card-planos.bordered {
  .title-plan {
    z-index: 2;
    position: relative;
    display: block;
    color: #cf522a;
    font-size: 3em;
    line-height: 40px;
    margin-top: 30px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .hover-1.open {
    .title-plan {
      color: #fff;
      text-shadow: 0 0 5px rgba(255, 255, 255, 0.9);
    }

    .topo.bordered h5 {
      text-shadow: 0 0 5px rgba(255, 255, 255, 0.9);
    }
  }

  .hover-bg {
    background: linear-gradient(135deg, #21385f 0%, #0e2244 100%);
    border-radius: 7px 7px 7px 7px;
    position: relative;
    z-index: 1;
    box-shadow: 9px 6px 20px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
    .infos {
      padding-top: 100px;
      .height {
        margin-top: 20px;
      }
    }
    .hidden b {
      text-shadow: none !important;
    }
  }

  .topo {
    &.bordered {
      width: 200px;
      border-radius: 50%;
      height: 200px;
      display: block;
      margin: 0 auto;
      background: linear-gradient(90deg, #df825d 18%, #cf522a 100%);
      border: 7px solid white;
      overflow: hidden;
      z-index: 2;
      position: relative;
      box-shadow: 10px -7px 12px 0 rgba(0, 0, 0, 0.2);
      margin-bottom: -100px;
      small {
        position: absolute;
        top: 120px;
        right: 20px;
        font-weight: 700;
      }
    }
    h5 {
      font-size: 5em;
    }
  }
  a {
    text-transform: none;
    text-decoration: none;
  }
}

.plan {
  .topo {
    padding: 35px 10px 35px;
    color: #fff;
    &.bordered {
      width: 200px;
      border-radius: 50%;
      height: 200px;
      display: block;
      margin: 0 auto;
      background: linear-gradient(90deg, #df825d 18%, #cf522a 100%);
      border: 7px solid white;
      overflow: hidden;
      z-index: 2;
      position: relative;
      box-shadow: 10px -7px 12px 0 rgba(0, 0, 0, 0.2);
      margin-bottom: -100px;
      small {
        position: absolute;
        top: 120px;
        right: 20px;
        font-weight: 700;
      }
    }
    h5 {
      font-size: 5em;
      line-height: 100px;
      color: #fff;
      font-family: "Montserrat-light" !important;
    }
  }
  a {
    text-transform: none;
    text-decoration: none !important;
  }
}

.plano-resi-pop {
  .plan {
    .topo {
      &.bordered {
        margin-top: -135px;
        margin-bottom: 20px;
        h5 {
          padding: initial !important;
          font-size: 5em !important;
          color: #fff !important;
        }
      }
    }

    .flag-destaque {
      padding: 5px 10px;
      background-color: var(--color-primary);
      color: #fff;
      position: absolute;
      top: 60px;
      border-radius: 20px;
      margin-left: -67px;
      z-index: 3;
      box-shadow: 0px 5px 10px -1px #3e3e3ed9;
    }
  }

  .content-cards {
    padding-top: 8.3rem;
  }

  .box-cards-pop {
    margin-top: 0px !important;
  }
}

@media screen and(max-width: 63em) {
  .card-planos.bordered {
    width: 80%;
    // .topo {
    //   h5 {
    //     margin-top: 38px;
    //   }
    // }
  }
}
