%hover-item {
    position: relative;
    font-family: "Montserrat-light";
    border-radius: 25px !important;
    z-index: 1;
    background: linear-gradient(
        90deg,
        rgba(223, 130, 93, 1) 18%,
        rgba(207, 82, 42, 1) 100%
    );
    color: #fff !important;
}

%hover-item-before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px !important;
    background: linear-gradient(
        90deg,
        rgba(100, 111, 131, 1) 0%,
        rgba(14, 34, 68, 1) 50%
    );
    opacity: 0;
    transition: opacity 0.4s;
    z-index: -1;
}

button.btn-hover-primary {
    position: relative;
    border-radius: 25px !important;
    z-index: 1;
    background: linear-gradient(
        90deg,
        rgba(100, 111, 131, 1) 0%,
        rgba(14, 34, 68, 1) 50%
    );
    opacity: 0.8;
    transition: opacity 0.5s;
    &:hover {
        opacity: 1;
    }
}

.nav-item #central,
a.btn-hover,
button.btn-hover {
    @extend %hover-item;
}

.nav-item #central:before,
a.btn-hover:before,
button.btn-hover:before {
    @extend %hover-item-before;
}

a.btn-hover,
button.btn-hover {
    display: inline-block;
    transition: opacity 0.4s;
    &:hover {
        text-decoration: none;
    }
}

.nav-item #central:hover:before,
a.btn-hover:hover:before,
button.btn-hover:hover:before {
    opacity: 1;
}
