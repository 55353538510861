.home {
  @media screen and(max-width: 48em) {
    .hero-text-title {
      text-align: center;
      .redes-sociais,
      a.btn-hover {
        margin-left: 0px;
      }
    }
  }
}
