.navbar-collapse {
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  transition: all 0.4s;
}

a.nav-link.dropdown-toggle {
  cursor: default;
}

#menu {
  padding: 5px 7%;
  img {
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  }
  &.fixed {
    height: 80px;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.15);
    img {
      transform: scale(0.8);
    }
  }
}

@media screen and(min-width: 64em) {
  .navbar-collapse {
    flex-grow: initial !important;
    flex-basis: auto !important;
  }
  #menu.navbar {
    justify-content: center;
  }
}

@media screen and(max-width: 442px) {
  .navbar-brand {
    width: 70%;
  }
  .page-plans.hero-image-pop .hero-text p,
  .hero-image-corporativo .hero-text p {
    font-size: 24px;
  }
}

@media screen and(max-width: 63em) {
  button.navbar-toggler {
    &[aria-expanded="true"] .navbar-toggler-icon:before {
      content: "\f00d";
      font-family: FontAwesome;
      font-size: 25px;
    }

    & .navbar-toggler-icon:before {
      content: "\f0c9";
      font-family: FontAwesome;
      font-size: 25px;
    }
  }

  .navbar-collapse.collapse.show,
  .navbar-collapse.collapsing {
    background-color: #fff;
  }

  .navbar-collapse {
    .navbar-nav {
      .nav-item {
        text-align: center;
        padding: 5px;

        .dropdown-menu {
          .dropdown-item {
            text-align: center;
          }
        }

        #central {
          margin-top: 0;
          margin-left: 5px !important;
          margin-right: 5px !important;
        }
      }
    }
  }

  .navbar-collapse.menu-link.collapse.show,
  .navbar-collapse.menu-link.collapsing {
    width: 100%;
    margin-left: -15px;
    margin-right: -15px;
    padding: 25px 30px;
    .nav-item #central {
      margin-left: 0px;
    }
  }
}
