// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Custom CSS
@import "./styles.scss";

@import "./btn-hover.scss";

@import "./menu.scss";

@import "./mobile.scss";

@import "./card-plan.scss";

@import "./blog-content.scss";

@import "./home.scss";

@import "./footer.scss";
