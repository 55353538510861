.planos .card a.btn-hover:before,
.planos .card a.btn-hover:hover {
    padding: 20px 40px 20px 40px;
    height: 74px;
    border-radius: 40px !important;
}

.planos .card a {
    padding: 20px 40px 20px 40px;
    height: 74px;
    border-radius: 40px !important;
    display: inline-flex;
}

.central {
    button,
    input {
        outline: none !important;
    }

    input,
    button {
        border-radius: 25px;
        border: 1px solid #da581f;
        padding: 10px 15px;
        width: 100%;
        margin-bottom: 10px;
    }

    button {
        color: #fff;
        border: none;
        background: linear-gradient(
            90deg,
            rgba(223, 130, 93, 1) 18%,
            rgba(207, 82, 42, 1) 100%
        );

        &:before {
            opacity: 0;
            transition: opacity 0.4s;
        }

        &:hover {
            &:before {
                opacity: 1;
                background: linear-gradient(
                    90deg,
                    rgba(100, 111, 131, 1) 0%,
                    rgba(14, 34, 68, 1) 50%
                );
            }
        }
    }

    .fields {
        margin: 0 auto;
    }

    .title {
        text-align: center;
    }

    .link-uteis {
        list-style: none;
        font-size: 18px;
        margin-top: 10px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .link-uteis a {
        color: #d7510c;
        &:hover {
            font-weight: bold;
            text-decoration: none;
        }
    }
}

.containt-contact {
    button {
        color: #fff;
        border: none;
        background: linear-gradient(
            90deg,
            rgba(223, 130, 93, 1) 18%,
            rgba(207, 82, 42, 1) 100%
        );
        width: 100%;
        padding: 10px 15px;
        border-radius: 25px;

        &:before {
            opacity: 0;
            transition: opacity 0.4s;
        }

        &:hover {
            &:before {
                opacity: 1;
                background: linear-gradient(
                    90deg,
                    rgba(100, 111, 131, 1) 0%,
                    rgba(14, 34, 68, 1) 50%
                );
            }
        }
    }
}

.needs-validation,
.needs-validation-customer {
    .col-md-6.invalid,
    .col-md-12.invalid {
        input,
        textarea {
            border-color: red;
        }
        .invalid-feedback {
            display: block;
            margin-top: 0px;
            margin-bottom: 10px;
        }

        .invalid-feedback-email,
        .invalid-feedback-cpf {
            display: none;
        }
    }
    .col-md-6.invalid-email,
    .col-md-12.invalid-email {
        .invalid-feedback-email {
            display: block;
            margin-bottom: 10px;
            margin-top: 0px;
        }
    }

    .col-md-12.invalid-cpf,
    .col-md-6.invalid-cpf {
        .invalid-feedback-cpf {
            display: block;
            margin-bottom: 10px;
            margin-top: 0px;
        }
    }
}

.cobertura-page-hero {
    background-image: url(/assets/img/innon-banner-cobertura.png);
    background-repeat: no-repeat;
    background-position: right top;
    margin-top: 100px;
    min-height: 600px;

    .text-contents {
        padding: 150px 0px;
    }

    h1 {
        font-family: 'Montserrat-medium';
        color: #fff;
        font-size: 50px;
        margin-top: 0px;
    }

    h2 {
        font-family: 'Montserrat-medium';
        color: #fff;
        font-size: 40px;
        margin-top: 0px;
    }
}

.quem-somos-page {
    .lista-cobertura{
        margin-top: 15px;
        margin-left: 40px;

        li > a {
            font-size: 1.5rem;
            color: #0e2244;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                color: #d7510c;
            }
        }
    }
    
    .m-t-20{
        margin-top: 20px;
    }
}
